 .editorComponent {
     &div {
         background: #000;
     }
 }

 .input {
     background-color: transparent;
     border: none;
     border-bottom: 1px solid black;
     border-radius: unset;
     padding: 5px 5px 0px 5px;
     margin-bottom: 5px;
     width: 140px;
 }

 .incorrect {
     border: 2px solid red;
     border-radius: 16px;
 }

 .correct {
     border-radius: 16px;
     border: 2px solid green;
 }

.input-container {
  position: relative; // Контейнер для інпуту
}


  .correctAnswer {
    color: rgb(116, 212, 116);
    font-size: 0.875rem; /* або будь-який інший розмір */
    margin-bottom: 0.5rem;
    display: inline; 
    margin-right: 2px;
  }
  
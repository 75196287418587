@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #4c4c4c;
  cursor: default;
}

input,
select {
  border: 1px solid black;
}


.ProseMirror {
  background-color: white;
  width: 100%;
  min-height: 400px;
  padding: 8px;
}

.ProseMirror input {
  text-align: center;
  width: 20px;
  cursor: pointer;
  border: none;
  pointer-events: none;
  border-bottom: 1px solid black;

}

.ReactModal__Overlay {
  position: relative;
  z-index: 1000;
}

/* input{
  @apply p-2 rounded-xl border-none outline-none;
} */

/*primary color*/
.bg-cream {
  background-color: #fffaed;
}

/*font*/
body {
  font-family: 'Poppins', sans-serif;
}

.bg-yellow-500 {
  background-color: #F48C06;
}

.text-yellow-500 {
  color: #F48C06;
}

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 8px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.floating-4 {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-4 {
  0% {
    transform: translate(0, 0px);
  }

  50% {
    transform: translate(0, 8px);
  }

  100% {
    transform: translate(0, -0px);
  }
}

.text-darken {
  color: #2F327D;
}

.swiper {
  width: 100%;
  height: 400px;
  @apply h-[300px] lg:h-[400px];
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}
.opaque {
  opacity: 1 !important;
}